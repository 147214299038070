<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ updatingItem.code_name }} - {{ $t("labels.position") }}
      <v-spacer></v-spacer>
      <v-btn outlined color="success" class="mr-2" @click="showRackDialog">
        {{ $t("labels.rack") }}
      </v-btn>
      <v-btn outlined color="error" @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-autocomplete
            v-model="type"
            :items="typeOptions"
            class="c-input-small"
            :label="$t('labels.type')"
            outlined
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-text-field
            v-model="zone"
            class="c-input-small"
            :label="$t('labels.zone')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-text-field
            v-model.number="rackFrom"
            type="number"
            class="c-input-small"
            :label="$t('labels.rack_from')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-text-field
            v-model.number="rackTo"
            type="number"
            class="c-input-small"
            :label="$t('labels.rack_to')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="1">
          <v-btn
            color="primary"
            block
            @click="confirmRackQuantity"
            :disabled="isDisabledBtnRun"
            >Run</v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">{{ $t("labels.rack") }}</th>
              <th class="text-center">{{ $t("labels.bff") }}</th>
              <th class="text-center" style="width: 300px">
                {{ $t("labels.cell_type") }}
              </th>
              <th class="text-center">{{ $t("labels.from") }}</th>
              <th class="text-center">{{ $t("labels.to") }}</th>
              <th class="text-center">{{ $t("labels.lock_cell") }}</th>
              <th class="text-center">{{ $t("labels.level") }}</th>
              <th class="text-center">{{ $t("labels.is_assign_return") }}</th>
              <th
                style="width: 24px !important; min-width: 24px !important"
              ></th>
              <th>
                <v-btn
                  small
                  color="success"
                  :disabled="isDisabledBtnConfirm"
                  @click="saveLocation"
                  >{{ $t("labels.confirm") }}</v-btn
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="`r_${index}`">
              <td class="text-center">
                <span class="text-h6 primary--text">{{ item.rack }}</span>
              </td>
              <td>
                <v-text-field
                  v-model.number="item.bff"
                  type="number"
                  class="c-input-small"
                  :label="$t('labels.bff')"
                  outlined
                  dense
                  hide-details
                  single-line
                  @input="onBffChange(item)"
                ></v-text-field>
              </td>
              <td>
                <v-autocomplete
                  v-model="item.cell_type"
                  :items="cellTypes"
                  class="c-input-small"
                  :label="$t('labels.cell_type')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.children"
                  :key="`f_${index}_${cIndex}`"
                  class="my-1"
                >
                  <v-text-field
                    v-model.number="cItem.from"
                    type="number"
                    class="c-input-small"
                    :label="$t('labels.from')"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.children"
                  :key="`t_${index}_${cIndex}`"
                  class="my-1"
                >
                  <v-text-field
                    v-model.number="cItem.to"
                    type="number"
                    class="c-input-small"
                    :label="$t('labels.to')"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.children"
                  :key="`lc_${index}_${cIndex}`"
                  class="my-1"
                >
                  <v-text-field
                    v-model="cItem.lock_cell"
                    class="c-input-small"
                    :label="$t('labels.lock_cell')"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.children"
                  :key="`l_${index}_${cIndex}`"
                  class="my-1"
                >
                  <v-text-field
                    v-model.number="cItem.level"
                    type="number"
                    class="c-input-small"
                    :label="$t('labels.level')"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.children"
                  :key="`l_${index}_${cIndex}`"
                  class="my-1"
                >
                  <v-autocomplete
                    v-model="cItem.is_assign_return"
                    :items="yesNoOptions"
                    class="c-input-small"
                    :label="$t('labels.is_assign_return')"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-autocomplete>
                </div>
              </td>
              <td style="width: 24px !important; min-width: 24px !important">
                <div
                  v-for="(cItem, cIndex) in item.children"
                  :key="`d_${index}_${cIndex}`"
                  class="my-1 d-flex align-center"
                  style="height: 40px; width: 24px"
                >
                  <div v-if="cIndex > 0">
                    <v-icon
                      color="error"
                      class="cursor-pointer"
                      @click="removeChildItem(item, index, cIndex)"
                      >mdi-close</v-icon
                    >
                  </div>
                </div>
              </td>
              <td class="text-center">
                <v-btn
                  x-small
                  color="warning"
                  @click="addChildItem(item, index)"
                  >{{ $t("labels.add") }}</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="rackDialog" persistent max-width="100vw">
      <Rack
        v-if="rackDialog"
        :id-warehouse="updatingItem.id"
        @close="hideRackDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { calIndexing, YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "CreateWarehouseLocation",
  components: {
    Rack: () => import("@/components/warehouse/Rack"),
  },
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    rackDialog: false,
    type: null,
    zone: null,
    rackFrom: null,
    rackTo: null,
    items: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    cellTypes: [],
    typeOptions: [
      {
        text: "_12_",
        value: 1,
      },
      {
        text: "1_2",
        value: 2,
      },
    ],
    cellGroupStamp: {},
  }),
  computed: {
    isDisabledBtnRun() {
      return !(this.type && this.rackFrom && this.rackTo);
    },
    isDisabledBtnConfirm() {
      if (!this.items || this.items.length === 0) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getCellTypes();
  },
  methods: {
    showRackDialog() {
      this.rackDialog = true;
    },
    hideRackDialog() {
      this.rackDialog = false;
    },
    onBffChange(item) {
      if (!item.bff) {
        const items = [...this.items];
        const findIndex = items.findIndex((i) => i.bff === item.rack);
        if (findIndex >= 0) {
          items[findIndex].bff = null;
          this.items = [...items];
        }
      }
    },
    async getCellTypes() {
      const { data } = await httpClient.post("/warehouse/v1/all-cell-type");
      this.cellTypes = data.map((e) => ({
        value: e.id,
        text: e.name,
      }));
    },
    close() {
      this.$emit("close");
    },
    confirmRackQuantity() {
      const items = [];
      for (let i = this.rackFrom; i <= this.rackTo; i++) {
        let bff = null;
        const rack = i;
        if (rack % 2 === 0) {
          bff = this.type === 1 ? rack + 1 : rack - 1;
        } else {
          bff = this.type === 1 ? rack - 1 : rack + 1;
        }
        if (bff < this.rackFrom || bff > this.rackTo) {
          bff = null;
        }

        const item = {
          rack,
          bff,
          children: [
            {
              from: null,
              to: null,
              lock_cell: null,
              level: null,
              cell_type: null,
              is_assign_return: 1,
            },
          ],
        };
        items.push(item);
      }
      this.items = [...items];
    },
    addChildItem(item, index) {
      const items = [...this.items];
      item.children.push({
        from: null,
        to: null,
        lock_cell: null,
        level: null,
        cell_type: null,
        is_assign_return: 1,
      });
      items[index] = { ...item };
      this.items = [...items];
    },
    removeChildItem(item, index, cIndex) {
      const items = [...this.items];
      items[index].children = [...item.children].filter((i, k) => k !== cIndex);
      this.items = [...items];
    },
    async saveLocation() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      const id_warehouse = this.updatingItem.id;
      const racks = [];
      const lockCells = [];
      this.items.forEach((item) => {
        // const child
        const childLevel = [...item.children].map((c) => c.level);
        const childFrom = [...item.children].map((c) => c.from);
        const childTo = [...item.children].map((c) => c.to);
        const max_level = Math.max(...childLevel);
        const start_cell = Math.min(...childFrom);
        const max_cell_each_level = Math.max(...childTo);

        item.children.forEach((c) => {
          if (c.lock_cell) {
            const cells =
              c.lock_cell.split(",") || c.lock_cell.split(";") || [];
            cells.forEach((c) => {
              lockCells.push({
                position: item.rack,
                cell: c,
              });
            });
          }
        });

        const rack = {
          id_warehouse: id_warehouse,
          zone: this.zone || "",
          position: item.rack,
          bff: item.bff || null,
          id_racks_type: item.cell_type,
          max_level: max_level,
          max_cell_each_level: max_cell_each_level,
          start_cell: start_cell,
        };

        racks.push(rack);
      });

      const locations = [];
      racks.forEach((r) => {
        for (let row = r.max_level - 1; row >= 0; row--) {
          for (let cell = r.start_cell; cell <= r.max_cell_each_level; cell++) {
            if (cell != 0) {
              const cell_id = this.getPositionCode(r, row, cell);
              const level = this.getCharByIndex(row);
              const inactive = !!lockCells.find(
                (lc) => lc.position == r.position && lc.cell == cell
              );
              locations.push({
                id_warehouse: id_warehouse,
                zone: this.zone || "",
                cell_id: cell_id,
                id_racks_type: r.id_racks_type,
                position: r.position,
                level: level,
                is_assign_return: r.is_assign_return || 0,
                cell: cell,
                indexing: calIndexing(
                  this.zone,
                  r.position,
                  level,
                  cell,
                  this.type
                ),
                active: !inactive,
                soft_delete: inactive,
              });
            }
          }
        }
      });

      try {
        console.log("data length", JSON.stringify({ racks, locations }).length);
        const { data } = await httpClient.post(
          "/warehouse/v1/create-rack-location",
          { id_warehouse, racks, locations }
        );

        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const key = `${item.stamp_template}|${item.stamp_font_size}`;
          if (!this.cellGroupStamp[key]) {
            this.cellGroupStamp[key] = [];
          }
          this.cellGroupStamp[key].push(item.cell_id);
        }

        await this.downloadStampFiles();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async downloadStampFiles() {
      const keys = Object.keys(this.cellGroupStamp);
      const self = this;
      for (let k = 0; k < keys.length; k++) {
        const key = keys[k];
        const [stampSize, fontSize] = key.split("|");
        const items = [...self.cellGroupStamp[key]].map((item) => ({
          qrcode: item,
          text: item,
        }));
        await self.downloadExcelFile(
          `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-pdf`,
          {
            size: stampSize,
            font: fontSize,
            items: items,
          },
          `${stampSize}_x${items.length}_stamp.pdf`
        );
      }
      this.$vToastify.success(this.$t("messages.create_success"));
      this.isLoading = false;
      this.close();
    },

    getCharByIndex(level) {
      return String.fromCharCode(level + 65);
    },
    getPositionCode(rack, row, cell) {
      return `${rack.zone || ""}${rack.position}${this.getCharByIndex(
        row
      )}${cell}`;
    },
  },
};
</script>

<style scoped></style>
